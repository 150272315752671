<template>
  <div>
    <FocusedList 
      v-if="!loading"
      :products="products"
      :isUser="isUser"
      @favoriteHandler="favoriteHandler"
      class="with-bg-green"
    >
      <template v-slot:title>
        <div class="focus-title my-10">
          <h1 class="main-text mb-5 text-left">
            Most <span class="text-orange">Favorites</span>
          </h1>
          <p class="sub-text text-left">
            Rent our stunning collection of most loved and most popular fine jewelry.
          </p>
        </div>

        <div class="mt-30">
          <a href="/rent-jewelry" class="btn btn-outline-dark text-white border-white w-100 py-10">BROWSE JEWELRY</a>
        </div>
      </template>
      
    </FocusedList>
  </div>
</template>

<script>
import * as Toastr from 'toastr';
import FocusedList from "../products/focused_list.vue";

export default {
  name: 'HowItWorksProducts',
  components: {
    FocusedList
  },
  data() {
    return {
      loading: true,
      products: [],
      isUser: false
    }
  },
  mounted() {
    this.getProductsFavorites();
  },
  methods: {
    async getProductsFavorites() {
      this.getProductsByCollection('for-carousel-favorites', 'products');
    },
    getProductsByCollection(collectionSlug, dataKey) {
      try {
        this.loading = true;
        $.ajax({
          method: "GET",
          url: `/get-products?collections_slugs=${collectionSlug}`,
          success: (data) => {
            this[dataKey] = data.products
            
            this.isUser = data.is_user;
            this[dataKey].forEach((el) => {
              el.rental_price.cents = (el.rental_price.cents / 100).toFixed(2);
              el.market_price.cents = (el.market_price.cents / 100).toFixed(2);
              el.fav_href = "/favorites/" + el.slug;
            });
            this.loading = false;
            return this[dataKey];
          },
          error: function (res) {
            Toastr.error(res.statusText, "Error fetching products.");
          },
        });
      } catch (e) {
        Toastr.error("Error fetching new arriaval products");
        this.loading = false;
      }

    },
    favoriteHandler: (product) => {
      product.favourite = !product.favourite;
    }
  }
}
</script>
